var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sideBar"},[(this.$route.params.slug)?_c('div',{staticClass:"sideBarPrograms"},[_c('p',{staticClass:"title1 sideBarPrograms__title"},[_vm._v("Мои программы")]),_c('SelectCourses',{attrs:{"activeCourse":this.$store.getters.getMember &&
                    this.$store.getters.getMember.data &&
                    this.$store.getters.getMember.data.courseName,"options":this.$store.getters.getUserPrograms}})],1):_vm._e(),(_vm.isVisibleCertificateOnboarding)?_c('div',{staticClass:"popup-layout"}):_vm._e(),(_vm.isVisibleCertificateOnboarding)?_c('OnboardModal',{staticStyle:{"left":"275px","top":"54%"},attrs:{"title":"Поздравляем с окончанием курса!","description":"Теперь Вы можете посмотреть и скачать сертификат об его успешном прохождении.","isNextButton":true,"nextButtonTitle":"ПОНЯТНО","onClose":function () { return _vm.showedBonusesOnboarding(); },"onCloseModal":function () { return _vm.showedBonusesOnboarding(); }}}):_vm._e(),_c('div',{staticClass:"sideBarList"},_vm._l((_vm.sideBarList),function(item){return _c('div',{key:item.title,on:{"mouseenter":function () { return _vm.mouseIn(item.title); }}},[(item.hasAccess && item.isCurrentStatus === 'active')?_c('router-link',{staticClass:"sideBarItem",attrs:{"to":item.link}},[_c('div',{staticClass:"sideBarItem__img"},[_c('inline-svg',{attrs:{"src":item.image}})],1),_c('span',{staticClass:"sideBarItem__text",domProps:{"innerHTML":_vm._s(item.title)}})]):_c('div',{staticClass:"sideBarItem sideBarItem--disabled"},[_c('div',{staticClass:"sideBarItem__main"},[(
                            item.isCurrentStatus === 'banned' &&
                                _vm.titleToEqual === item.title
                        )?_c('Tooltip',{attrs:{"tooltipDirection":"right","text":"В момент блокировки другие разделы личного кабинета недоступны, но после разблокировки Вы с лёгкостью сможете продолжить обучение"}}):_vm._e(),(
                            item.isCurrentStatus === 'blocked' &&
                                _vm.titleToEqual === item.title
                        )?_c('Tooltip',{attrs:{"tooltipDirection":"right","text":"В момент заморозки другие разделы личного кабинета недоступны, но после ее окончания Вы с лёгкостью сможете продолжить обучение"}}):_vm._e(),_c('div',{staticClass:"sideBarItem__img"},[_c('inline-svg',{attrs:{"src":item.image}})],1),_c('span',{staticClass:"sideBarItem__text",domProps:{"innerHTML":_vm._s(item.title)}})],1),(_vm.member.isGraduated && item.title === 'Вопрос эксперту')?_c('Tooltip',{staticClass:"sideBarItem__vip",attrs:{"tooltipType":"small","tooltipDirection":"right","tooltipIcon":require('@/assets/img/pages/webinars/vip.svg'),"text":"Данная активность для Вас недоступна, так как Вы уже закончили своё обучение"}}):(!item.hasAccess && _vm.activeCourseName !== 'VIP')?_c('VipUpsellButton',{staticClass:"sideBarItem__vip",attrs:{"tooltipType":"small","tooltipDirection":"right"}}):_vm._e()],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }