var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selectCourses"},[(_vm.activeCourse)?_c('div',{class:this.isListActive
                ? 'select select--active input--fixedHeight selectCoursesCurrent'
                : 'select input--fixedHeight selectCoursesCurrent',on:{"click":function () { return (this$1.isListActive = !this$1.isListActive); }}},[_vm._v(" "+_vm._s(_vm.activeCourse)+" ")]):_vm._e(),(this.isListActive)?_c('div',{staticClass:"selectCoursesOptions"},[_vm._l((_vm.options),function(item){return _c('div',{key:item.slug},[(item.slug === 'trejding_ot_a_do_ya_3_5')?_c('router-link',{class:item.slug === _vm.$route.params.slug
                        ? 'text2 colorWhite selectCoursesOption selectCoursesOption--active'
                        : 'text2 colorBlack selectCoursesOption',attrs:{"to":'/' + item.slug + '/dashboard'},nativeOn:{"click":function($event){return (function () { return _vm.hideMenu(); }).apply(null, arguments)}}},[_c('span',{staticClass:"selectCoursesOption__text"},[_vm._v(_vm._s(item.name))])]):_c('a',{class:item.slug === _vm.$route.params.slug
                        ? 'text2 colorWhite selectCoursesOption selectCoursesOption--active'
                        : 'text2 colorBlack selectCoursesOption',attrs:{"href":'https://edu.gerchik.ru/programs/' + item.slug,"target":"_blank"},nativeOn:{"click":function($event){return (function () { return _vm.hideMenu(); }).apply(null, arguments)}}},[_c('span',{staticClass:"selectCoursesOption__text"},[_vm._v(_vm._s(item.name))])])],1)}),_c('div',{staticClass:"selectCoursesOption selectCoursesOption--btn"},[_c('Button',{staticClass:"selectCoursesOption__btn",attrs:{"isLink":true,"link":"/","title":"смотреть все программы","extraClass":"button--fullWidth button--bordered"}})],1)],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }