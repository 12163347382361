<template>
    <div class="sideBar">
        <div v-if="this.$route.params.slug" class="sideBarPrograms">
            <p class="title1 sideBarPrograms__title">Мои программы</p>
            <SelectCourses
                :activeCourse="
                    this.$store.getters.getMember &&
                        this.$store.getters.getMember.data &&
                        this.$store.getters.getMember.data.courseName
                "
                :options="this.$store.getters.getUserPrograms"
            />
        </div>

        <div class="popup-layout" v-if="isVisibleCertificateOnboarding"></div>
        <OnboardModal
            style="left: 275px; top: 54%"
            v-if="isVisibleCertificateOnboarding"
            title="Поздравляем с окончанием курса!"
            description="Теперь Вы можете посмотреть и скачать сертификат об его успешном прохождении."
            :isNextButton="true"
            nextButtonTitle="ПОНЯТНО"
            :onClose="() => showedBonusesOnboarding()"
            :onCloseModal="() => showedBonusesOnboarding()"
        />
        <div class="sideBarList">
            <div
                v-for="item in sideBarList"
                :key="item.title"
                @mouseenter="() => mouseIn(item.title)"
            >
                <router-link
                    v-if="item.hasAccess && item.isCurrentStatus === 'active'"
                    :to="item.link"
                    class="sideBarItem"
                >
                    <div class="sideBarItem__img">
                        <inline-svg :src="item.image"></inline-svg>
                    </div>
                    <span class="sideBarItem__text" v-html="item.title"></span>
                </router-link>
                <div v-else class="sideBarItem sideBarItem--disabled">
                    <div class="sideBarItem__main">
                        <Tooltip
                            v-if="
                                item.isCurrentStatus === 'banned' &&
                                    titleToEqual === item.title
                            "
                            tooltipDirection="right"
                            text="В момент блокировки другие разделы личного кабинета недоступны, но после разблокировки Вы с лёгкостью сможете продолжить обучение"
                        />
                        <Tooltip
                            v-if="
                                item.isCurrentStatus === 'blocked' &&
                                    titleToEqual === item.title
                            "
                            tooltipDirection="right"
                            text="В момент заморозки другие разделы личного кабинета недоступны, но после ее окончания Вы с лёгкостью сможете продолжить обучение"
                        />
                        <div class="sideBarItem__img">
                            <inline-svg :src="item.image"></inline-svg>
                        </div>
                        <span class="sideBarItem__text" v-html="item.title"></span>
                    </div>
                    <Tooltip
                        v-if="member.isGraduated && item.title === 'Вопрос эксперту'"
                        tooltipType="small"
                        tooltipDirection="right"
                        class="sideBarItem__vip"
                        :tooltipIcon="require('@/assets/img/pages/webinars/vip.svg')"
                        text="Данная активность для Вас недоступна, так как Вы уже закончили своё обучение"
                    />
                    <VipUpsellButton
                        v-else-if="!item.hasAccess && activeCourseName !== 'VIP'"
                        class="sideBarItem__vip"
                        tooltipType="small"
                        tooltipDirection="right"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SelectCourses from "@/views/components/SelectCourses/SelectCourses";
import VipUpsellButton from "@/views/components/VipUpsellButton/VipUpsellButton";
import Tooltip from "@/views/components/Tooltip/Tooltip";

import OnboardModal from "@/views/components/OnboardModal/OnboardModal";

import iconDashboard from "../../../assets/img/partials/sideBar/item-dashboard.svg";
import iconStudy from "../../../assets/img/partials/sideBar/item-study.svg";
import iconWebinars from "../../../assets/img/partials/sideBar/item-webinars.svg";
import iconBonuses from "../../../assets/img/partials/sideBar/item-bonuses.svg";
import iconKnowledgeBase from "../../../assets/img/partials/sideBar/item-knowledgeBase.svg";
import iconReviews from "../../../assets/img/partials/sideBar/item-reviews.svg";
import iconCuratorChat from "../../../assets/img/partials/sideBar/item-curatorChat.svg";
import iconItemCertificate from "../../../assets/img/partials/sideBar/item-certificate.svg";
import iconItemOnboarding from "../../../assets/img/partials/sideBar/onboarding-icon.svg";

export default {
    name: "sideBar",

    components: {
        SelectCourses,
        VipUpsellButton,
        Tooltip,
        OnboardModal,
    },

    data() {
        return {
            isVisibleCertificateOnboarding: false,
            titleToEqual: "",
        };
    },

    methods: {
        mouseIn(title) {
            this.titleToEqual = title;
        },
    },

    computed: {
        member: function() {
            return (
                (this.$store.getters.getMember && this.$store.getters.getMember.data) ||
                {}
            );
        },

        enabled: function() {
            return (
                (this.$store.getters.getMember &&
                    this.$store.getters.getMember.data &&
                    this.$store.getters.getMember.data.enabled) ||
                {}
            );
        },

        sideBarList: function() {
            return [
                {
                    image: iconDashboard,
                    title: "Дашборд",
                    link: "/" + this.$route.params.slug + "/dashboard",
                    hasAccess: true,
                    isCurrentStatus: this.isUserCurrentStatus,
                },
                {
                    image: iconStudy,
                    title: "Обучение",
                    link: "/" + this.$route.params.slug + "/study",
                    hasAccess: this.enabled && this.enabled.study,
                    isCurrentStatus: this.isUserCurrentStatus,
                },
                {
                    image: iconWebinars,
                    title: "Вебинары",
                    link: "/" + this.$route.params.slug + "/webinars",
                    hasAccess:
                        this.enabled &&
                        this.enabled.webinars &&
                        this.enabled.webinars.enabled,
                    isCurrentStatus: this.isUserCurrentStatus,
                },
                {
                    image: iconBonuses,
                    title: "Бонусы",
                    link: "/" + this.$route.params.slug + "/bonuses",
                    hasAccess: this.enabled && this.enabled.bonuses,
                    isCurrentStatus: this.isUserCurrentStatus,
                },
                // {
                //     image: iconKnowledgeBase,
                //     title: "База Знаний",
                //     link: "/" + this.$route.params.slug + "/knowledge-base",
                // },
                {
                    image: iconCuratorChat,
                    title: "Вопрос куратору",
                    link: "/" + this.$route.params.slug + "/questions",
                    hasAccess: this.enabled && this.enabled.questions,
                    isCurrentStatus: this.isUserCurrentStatus,
                },
                {
                    image: iconReviews,
                    title: "Отзывы",
                    link: "/" + this.$route.params.slug + "/reviews",
                    hasAccess: true,
                    isCurrentStatus: this.isUserCurrentStatus,
                },
                {
                    image: iconItemCertificate,
                    title: "Сертификат",
                    link: "/" + this.$route.params.slug + "/certificate",
                    hasAccess: true,
                    isCurrentStatus: this.isUserCurrentStatus,
                },
                {
                    image: iconItemOnboarding,
                    title: "Ознакомление<br> с курсом",
                    link: "/" + this.$route.params.slug + "/dashboard?isTutorial=true",
                    hasAccess: true,
                    isCurrentStatus: this.isUserCurrentStatus,
                },
            ];
        },

        isUserCurrentStatus() {
            return (
                this.$store.getters.getDashboardActivityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member &&
                this.$store.getters.getDashboardActivityStatus.activityStatus.member
                    .status
            );
        },
        activeCourseName() {
            return this.$store.getters.getDashboardActivityStatus.activityStatus.member
                .accessLevel.name;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./sideBar.scss";
</style>

<style lang="scss">
@import "./sideBar.scss";
</style>
