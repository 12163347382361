<template>
    <div class="selectCourses">
        <div
            v-if="activeCourse"
            :class="
                this.isListActive
                    ? 'select select--active input--fixedHeight selectCoursesCurrent'
                    : 'select input--fixedHeight selectCoursesCurrent'
            "
            @click="() => (this.isListActive = !this.isListActive)"
        >
            {{ activeCourse }}
        </div>

        <div v-if="this.isListActive" class="selectCoursesOptions">
            <div v-for="item in options" :key="item.slug">
                <router-link
                    v-if="item.slug === 'trejding_ot_a_do_ya_3_5'"
                    :to="'/' + item.slug + '/dashboard'"
                    :class="
                        item.slug === $route.params.slug
                            ? 'text2 colorWhite selectCoursesOption selectCoursesOption--active'
                            : 'text2 colorBlack selectCoursesOption'
                    "
                    @click.native="() => hideMenu()"
                >
                    <span class="selectCoursesOption__text">{{ item.name }}</span>
                </router-link>
                <a
                    v-else
                    :href="'https://edu.gerchik.ru/programs/' + item.slug"
                    :class="
                        item.slug === $route.params.slug
                            ? 'text2 colorWhite selectCoursesOption selectCoursesOption--active'
                            : 'text2 colorBlack selectCoursesOption'
                    "
                    target="_blank"
                    @click.native="() => hideMenu()"
                >
                    <span class="selectCoursesOption__text">{{ item.name }}</span>
                </a>
            </div>
            <div class="selectCoursesOption selectCoursesOption--btn">
                <Button
                    :isLink="true"
                    link="/"
                    title="смотреть все программы"
                    extraClass="button--fullWidth button--bordered"
                    class="selectCoursesOption__btn"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
export default {
    name: "SelectCourses",

    components: {
        Button,
    },

    data() {
        return {
            isListActive: false,
        };
    },

    props: {
        activeCourse: String,
        options: {
            name: String,
            slug: String,
            isSelected: Boolean,
        },
    },

    methods: {
        hideMenu() {
            this.isListActive = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "SelectCourses.scss";
</style>
